<template>
  <div class="modal-backdrop"  @click.self="$emit('close')">
    <div class="modal">
      <h2 class="align-self-center">برداشت ریالی</h2>
      <p class="regular">درخواست تسویه ریالی در روزهای تعطیل به دلیل تعطیلی بانک و عدم فعالیت سیستم پایا انجام نمی شود
        درخواست تسویه های
        بالای 100 میلیون تومان به دلیل محدودیت های بانکی حداکثر تا یک روز آینده کاری از زمان ثبت درخواست انجام خواهد
        شد</p>
      <div class="content-container d-flex flex-col">
        <div class="d-flex justify-space-between w100 cont">
          <span class="small gray">موجودی شما:</span>
          <span class="small gray">{{ `${ $toLocal(credit,0) } TMN` }}</span>
        </div>
        <input @input="data.amount = $toLocal(data.amount)" :disabled="otpSent" v-model="data.amount" class="input w100" type="text" placeholder="مبلغ برداشت">
        <input :disabled="otpSent" v-model="data.destinationWalletAddress" class="input w100" type="text" placeholder="شماره شبا">
        <input v-if="otpSent" v-model="data.code" class="input w100" type="text" placeholder="شناسه دو عاملی">
        <span  v-if="otpSent" class="timer align-self-end small">{{Math.floor(countdown/60)}}:{{countdown%60}} </span>

      </div>
      <button class="btn-primary w100" :disabled="disable1" @click.prevent="send" v-if="!otpSent">{{disable1 ? disable1 : 'ارسال کد'}}</button>
      <button class="btn-primary w100" v-if="timeout" @click="countdown=10,timeout=!timeout,countDownTimer()">ارسال مجدد</button>
      <button class="btn-primary w100" :disabled="disable2" v-if="!timeout && otpSent" @click="submit">{{disable2 ? disable2 : 'تایید'}}</button>
    </div>

  </div>
</template>

<script>
export default {
  name: "RialWithdraw",
  props:['credit'],
  data() {
    return {
      otpSent: false,
      countdown:90,
      timeout:false,
      timeOut:null,

      data:{
                amount:'',
                destinationWalletAddress:'',
                relatedCoin:'TOMAN',
                code:'',
            },
    }
  },
  computed: {
    disable1(){
      let err
        if(!this.data.amount){
          err= 'مقدار را وارد کنید'
        }else if(!this.data.destinationWalletAddress){
            err= 'شماره شبا را وارد کنید'
        }else{
          err = false
        }
      return err
    },
    disable2(){
      let err
      if(!this.data.code){
        err = 'کد را وارد کنید'
      }else{
        err = false
      }
      return err
    },
  },
  methods:{
    countDownTimer() {
      if (this.countdown > 0) {
        this.timeOut = setTimeout(() => {
          this.countdown--
          this.countDownTimer()
        }, 1000)
      } else {
        this.$error('زمان شما به اتمام رسید', '')
        this.timeout = true
      }
    },
    async submit() {
            this.state.loading = true
            this.data.amount = this.$A2N(this.data.amount)
            const [res, ] = await this.$http.post('/wallets/withdrawal-request', this.data)
            if (res) {
                this.$emit('close')
                this.$error('درخواست شما با موفقیت ثبت شد', '', 'success')
                this.closeModale();
                // ++this.state.requestChanged

            }

    },
    async send() {
            this.data.code = ''
            this.state.loading = true
            const a = {
                EMAIL: 'ایمیل',
                SMS: 'شماره'
            }
            const res = await this.$axios.post("/users/send-code")
            if (res.message === 'code sent successfully.') {
                this.$error('', 'کد به ' + a[res.baseDTO.towStepAuthenticationType] + ' شما ارسال شد', 'success')
                this.countDown = 90
                this.countDownTimer()
                this.otpSent =  true
            }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeOut)
  }
}
</script>

<style lang="scss" scoped>
.timer {
  color: var(--primary);
}
.cont{
  position: relative;
}
.input {
  background: #F0F0F0;
  border-radius: 8px 8px 0px 0px;
  height: 48px;
  padding: 10px;
}

.modal {
  display: flex;
  flex-direction: column;
  //align-items: center;
  flex-grow: 1;
  max-width: 400px;
  min-height: 200px;
  background: var(--darker-white);
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 24px;
  gap: 28px;
}

.content-container {
  gap: 24px;
}

button {
  height: 44px;
}
@media (max-width:500px){
  .modal{
    max-width: 90%;
    gap: .5rem;
    max-height: 400px;
    overflow: auto;
  }
}
</style>