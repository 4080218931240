<template>
<div class="modal-backdrop" @click.self="closeModal" >
  <div class="modal">
    <h2>واریز ریالی</h2>
    <p class="regular">
      جهت افزایش اعتبار کیف پول خود با استفاده از کارت های بانکی عضو شبکه شتاب و از طریق درگاه پرداخت،حتما باید از کارت های بانکی به نام خودتان که در پروفایلتان تایید شده است استفاده نمایید.
    </p>
    <input @input="amount = $toLocal(amount,0)" type="text" class="selector" placeholder="مبلغ واریزی" v-model="amount">
    <div @click="drop = !drop" name="cars" id="cars" class="selector" placeholder="انتخاب شماره کارت">
      <span v-if="!selectedCart.cardNumber"> انتخاب شماره کارت </span> 
      <span v-if="selectedCart.cardNumber"> {{selectedCart.cardNumber}} </span> 
      <transition name="hei">
      <div v-if="drop" class="drop-down">  
        <div v-for="(card,index) in Cards" @click="selectCart(card)" :key="index" class="span-container">
          <span> {{card.cardNumber}} </span>
        </div>
      </div>
      </transition>
    </div>
    <button class="btn-primary w100" :disabled="disable" @click.prevent="deposit" > {{disable ? disable:'تایید'}} </button>
  </div>
</div>
</template>

<script>
export default {
  name: "RialDeposit",
  data(){
    return{
      drop:false,
      amount:0,
      selectedCart:{},
    }
  },
  methods: {
    selectCart(val){
      this.selectedCart = val
    },
      closeModal(){
        this.$emit('close',false)
      },
      async deposit() {
            this.state.loading = true
            let walletInfo = {
                amount: this.$S2N(this.amount),
                paymentGateway: 'ZarrinPal',
                cardNumber: this.selectedCart.cardNumber,
                callBackURL: this.$callBackURL
            }

            const res = await this.$axios.post('/wallets/deposit-toman', walletInfo)

            if (res.message === "Deposit Request Created Successfully") {
                this.closeModal();
                window.open(res.baseDTO.redirectSite, "_self")
            }
        },
    },
  computed: {
    disable(){
      let err 
        if(!this.amount){
          err = 'مقدار را وارد کنید'
        } else if(!this.selectedCart.cardNumber){
          err = 'کارت را انتخاب کنید'
        }
        else{
          err = false
        }
      return err
    },
    Cards(){
      let allCards
        allCards = this.state.userInfo.bankAccounts.filter(a => a.status == 'APPROVED') 
      return allCards
    }
  },
}
</script>

<style lang="scss" scoped>
.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  max-width: 460px;
  gap: 28px;
  min-height: 300px;
  background: var(--darker-white);
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 24px;
}
.selector{
  cursor: pointer;
  width: 100%;
  background: #F0F0F0;
  border-radius: 8px 8px 0px 0px;
  height: 48px;
  padding: 10px;
  position: relative;
  .drop-down{
    z-index: 5;
    position: absolute;
    top: 40px;
    right: 0;
    width: 100%;
    height: 120px;
    overflow-y: scroll;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 10px;
    border-radius: 0px 0px 8px 8px;
    .span-container{
      width: 100%;
      border-radius: 3px ;
      background: white;
      padding: 4px;
    }
  }
}
button{
  height: 44px;
}
@media (max-width:500px){
  .modal{
    max-width: 90%;
    gap: .5rem;
    max-height: 400px;
    overflow: auto;
  }
}
</style>