<template>
<div class="modal-backdrop" @click.self="$emit('close')">
<div class="modal">
  <h3 class="align-self-center">واریز {{$coinLabel[wallet.relatedCoin]}}   </h3>
  <div v-if="link" class="modal-wrapper">
    <p class="regular">
      لینک زیر آدرس کیف پول شما می باشد. برای واریز کوین می توانید از این آدرس یا اسکن کد زیر استفاده کنید.
    </p>
    <img class="qrcode" :src="'data:image/png;base64,'+qrCode" >
    <div class="link-container">
      <span class="copy-alert alert-link small green" v-if="alertLink">کپی شد</span>
      <div class="input-container">
        <img src="@/assets/Icons/clipboard.svg" alt="" @click.prevent="copyLink(link)">
        <p>{{link}}</p>
      </div>


    </div>
    <div class="warning">
      <p class="regular">
        توجه کنید که واریز به هر آدرسی دیگری جز این ادرس موجب از دست رفتن دارایی شما می شود.این عمل بازگشت پذیر نخواهد بود و کندل هیچ مسولیتی را در این باره قبول نخواهد کرد
      </p>
    </div>

    <button class="btn-primary w100" @click="copyLink(link)">کپی</button>
    <button class="btn-primary w100" @click="checkDeposite"> بررسی واریز </button>
  </div>
  <div @click="drop = !drop" name="cars" id="cars" class="selector">
      <span v-if="!selectedTokenType"> انتخاب شبکه  </span> 
      <span v-if="selectedTokenType"> {{selectedTokenType.tokenType}} </span> 
      <transition name="hei">
      <div v-if="drop" class="drop-down">  
        <div v-for="(token,index) in TokenTypes" @click="selectToken(token)" :key="index" class="span-container">
          <span> {{token.tokenType}} </span>
        </div>
      </div>
      </transition>
  </div>

</div>

</div>
</template>

<script>
export default {
  name: "Deposit",
  props:['wallet'],
  data(){
    return{
      drop:false,
      link:'',
      alertLink:false,
      TokenTypes:null,
      selectedTokenType:null,
      qrCode:'',
    }
  },
  methods:{
    selectToken(token){
      this.selectedTokenType=token
      this.getQR();
    },
    async checkDeposite(){
                this.state.loading=true
                const res = await this.$axios.get('/wallets/check-confirmed-transaction',{
                    params:{
                        relatedCoin:this.wallet.relatedCoin,
                        tokenType:this.selectedTokenType.tokenType
                    }
                })
                res ? this.$error('پیگیری واریز','مقدار واریز شناسایی نشد','info','تایید',) : ''
                
    },
    async getQR() {
            this.state.loading = true
            const token = this.selectedTokenType.tokenType
            let params = {
                relatedCoin: this.wallet.relatedCoin
            }
            if (this.relatedCoin === 'TETHER') {
                params.tokenType = token
            }
            const res = await this.$axios('/wallets/customer/wallet-address?walletType=P2P&tokenType=' + token, {
                params
            }).catch(() => {
                this.closeModale();
                this.state.walletBtcDe = false
            })
            if (res) {
                this.link = res.baseDTO.address
                this.qrCode = res.baseDTO.qrCode
            }
            this.state.loading=false
    },
    async getData() {
                const res = await this.$axios.get('/coins/details')
                this.TokenTypes = res.content.filter( a => a.coin == this.wallet.relatedCoin )[0].tokenTypeDetails
                
    },
    copyLink(link) {
      navigator.clipboard.writeText(link);
      this.alertLink = true;
      setTimeout(() => {
        this.alertLink = false;
      }, 1500);
    },
  },
  mounted() {
    this.getData();
  },
}
</script>

<style lang="scss" scoped>
.qrcode{
    width: 180px;
    height: 180px;
    align-self: center;
}
.modal-wrapper{
  display: flex;
  flex-direction: column;
  //align-items: center;
  flex-grow: 1;
  gap: 28px;
}
.copy-alert {
  position: fixed;
  bottom: 100px;
  left: 50%;
  border-radius: 8px;
  background-color: var(--white);
  padding: 5px;
  box-shadow: 0 0 8px -1px white;
}
.warning{
  display: flex;
  gap: 2px;
}
.modal {
  display: flex;
  flex-direction: column;
  //align-items: center;
  flex-grow: 1;
  max-width: 400px;
  min-height: 200px;
  background: var(--darker-white);
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 24px;
  gap: 32px;
}

.link-container{
  display: flex;
  align-items: center;
  width: 100%;
  gap: 7px;
  flex-wrap: wrap;
  .regular{

    width: 100%;
    max-width: 5%;
    flex: 1 1 auto;
  }
  .input-container{
    overflow: scroll;
    flex: 1 1 auto;
    max-width: 491px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px;
    border-radius: 8px;
    background: var(--gray-lighter);
  }
}
button{
  height: 44px;
}

@media (max-width:500px){
  .modal{
    max-width: 90%;
    gap: .5rem;
    max-height: 400px;
    overflow: auto;
  }
}
</style>