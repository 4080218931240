<template>
<div class="wallets d-flex flex-col">
<Deposit :wallet="selectedWallet" v-if="Deposit" @close="Deposit=false"/>
  <Withdraw :wallet="selectedWallet" v-if="Withdraw" @close="Withdraw=false"/>
  <div class="table-container">
    <table class="w100">
      <tr class="tr-head">
        <td class="gray small bolder pr10">کیف پول</td>
        <td class="gray small bolder">موجودی</td>
        <td class="gray small bolder remove">معادل</td>
        <td class="gray small bolder pl10">عملیات</td>
      </tr>
      <tr class="tr-body" v-for="(wallet,index) in wallets" :key="index">
        <td class="small pr10">
          <div class="coin">
                    <img :src="'/coins/'+wallet.relatedCoin+'.png'" alt="">
                    <div class="unit remove2">
                        <span class="faunit">
                            {{$coinLabel[wallet.relatedCoin]}}
                        </span>
                        <span class="enunit">
                            {{wallet.relatedCoin}}
                        </span>
                    </div>
            </div>
        
        </td>
        <td class="small">{{$toLocal(wallet.credit,$decimal[wallet.relatedCoin])}}</td>
        <td class="small remove"> <span class="small gray">{{$coinUnit[wallet.relatedCoin]}}</span> {{$toLocal(wallet.wholePriceOfCredit,$decimal[wallet.relatedCoin])}} </td>
        <td class="pl10">
          <div class="dropDown-container">
            <inline-svg @click="selectedDropIndex=index" class="dots" :src="require('../../../assets/Icons/dots.svg')" />
            <div :style="selectedDropIndex==index ? 'z-index=3':''" v-if="selectedDropIndex==index" class="dropDown">
              <button class="" @click.prevent="depositWallet(wallet)">واریز</button>
              <button class="" @click.prevent="withdrawWallet(wallet)">برداشت</button>
              <button class="" @click.prevent="reD(wallet.relatedCoin)">معامله</button>
            </div>
          </div>
          <div class="btns d-flex justify-end flex-wrap align-items-center">
            <button class="btn-green-outline btn" @click.prevent="depositWallet(wallet)">واریز</button>
            <button class="btn-red-outline btn" @click.prevent="withdrawWallet(wallet)">برداشت</button>
            <button class="submit btn" @click.prevent="reD(wallet.relatedCoin)">معامله</button>
          </div>


        </td>

        

      </tr>
    </table>
  </div>
</div>
</template>

<script>
import Deposit from "@/components/Panel/WalletP2P/Deposit";
import Withdraw from "@/components/Panel/WalletP2P/Withdraw";
export default {
  name: "Wallets",
  components: {Withdraw, Deposit},
  props:['wallets','tether','toman'],
  data(){
    return{
      selectedDropIndex:null,
      Deposit:false,
      min:0.01,
      Withdraw:false,
      coin:'BTC',
      networkFee:'0.0005',
      selectedWallet:[],
    }
  },
  methods: {
      reD(val){
        console.log(val=='TETHER');
        val == 'TETHER' ? this.$router.push('/advancetrade/TOMAN/TETHER') : this.$router.push('/advancetrade/TETHER/'+val)
      },
      depositWallet(val){
        this.selectedWallet=val
        this.Deposit=true
      },
      withdrawWallet(val){
        this.selectedWallet=val
        this.Withdraw=true
      },
  },
  created:function(){
    window.addEventListener("click",event => {
      if(!event.target.closest('.dropDown-container')){
        this.selectedDropIndex=null;
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.dropDown-container{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  .dropDown{
    position: absolute;
    top: 0;
    left: 30px;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0px 0px 14px -1px rgba(65, 70, 192, 0.47);
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    z-index: 3;
    background: var(--white);
  }
}
.dots{
  margin-left: 10px;
  fill: var(--primary);
  transform: rotate(90deg);
}
.enunit{
    color: var(--a-hover) !important;
    font-weight: 400 !important;
    font-size: clamp(10px,1vw,12px) !important;
}
.coin{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
    img{
        width: 36px;
        height: 36px;
    }
    .unit{
        display: flex;
        flex-direction: column;
        row-gap: 4px;
    }
}



// .table-container{
//   // max-height: 408px;
//   overflow-y: scroll;
// }
.wallets{
  padding:1rem;
  // background: #F8F8F8;
  /* card/def */

  box-shadow: -1px 4px 8px -1px rgba(192, 222, 234, 0.2);
  border-radius: 12px;
}
table{
  border-collapse: collapse;

  td{
    height: 80px;
    min-height: 68px !important;
  }
td:last-child{
  text-align: left;
}

.tr-head{
  // background: #F8F8F8;
  position: sticky;
  top: 0;
}
.tr-body{
  &:hover{
    box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    border-radius: 4px;
  }
  td{
    border-bottom: 1px solid var(--gray-lighter);
  }
}



}

.td-maxwidth{
  // max-width: 500px;
  width: 420px;
}
.btns{
gap: 1rem;


  .btn{
height: 44px !important;
    flex:1 1 128px;
    width: 100%;
    max-width: 128px;
  }
}

@media only screen and(min-width:1000px){
  .dropDown-container{
    display: none !important;
  }
}
@media only screen and(max-width:1000px){
  .remove{
    display: none;
  }
  .btns{
    display: none;
    flex-direction: column;
    gap: 24px;
    align-items: flex-end;
    justify-content: space-around;
    button{
      flex: unset !important;
    }

  }
  .tr-body{
    padding-bottom: 20px;
  }
}
@media only screen and(max-width:500px){
  .unit{
    display: none !important;
  }
  button{
    max-width: 100px !important;
    flex: unset !important;
  }
}
@media only screen and(max-width:380px){
  button{
    max-width: 70px !important;
    text-align: center !important;
  }
}
</style>