<template>
  <div class="modal-backdrop" @click.self="$emit('close')">
    <div class="modal">
      <h2 class="align-self-center">
        برداشت
        {{ $coinLabel[wallet.relatedCoin] }}
      </h2>
      <div class="title-text d-flex flex-col">
        <p class="regular">
          درخواست های برداشت به صورت دستی تایید و سپس پردخت خواهند شد.کارمزد انتقال از مبلغ برداشت کسر خواهد شد.
        </p>
        <p class="regular">در صورتی که آدرس مقصد متعلق به کاربران هاب باشد انتقال به صورت مستقیم و آنی صورت می گیرد و کارمزد انتقال صفر
          خواهد بود.</p>
      <div @click="drop = !drop" name="cars" id="cars" class="selector">
        <span v-if="!selectedTokenType"> انتخاب شبکه  </span> 
        <span v-if="selectedTokenType"> {{selectedTokenType.tokenType}} </span> 
        <transition name="hei">
        <div v-if="drop" class="drop-down">  
          <div v-for="(token,index) in TokenTypes" @click="selectToken(token)" :key="index" class="span-container">
            <span> {{token.tokenType}} </span>
          </div>
        </div>
        </transition>
      </div>
      <div v-if="selectedTokenType" class="fee-network d-flex flex-col">
        <div class="row d-flex justify-space-between w100">
          <span class="d-flex small gray">
              <img src="@/assets/Icons/warning.png" alt="" width="20" height="20">
            کارمزد شبکه
          </span>
          <span class="small gray">{{selectedTokenType.withdrawalFee + '%'}}</span>
        </div>
        <div class="row d-flex justify-space-between w100">
          <span class="d-flex small gray">
              <img src="@/assets/Icons/warning.png" alt="" width="20" height="20">
          حداقل مقدار قابل برداشت
          </span>
        <span class="small gray rtl">  {{selectedTokenType.minWithdraw}} </span>
        </div>
        <div class="row d-flex justify-space-between w100">
          <span class="d-flex small gray">
              <img src="@/assets/Icons/warning.png" alt="" width="20" height="20">
          حداکثر مقدار قابل برداشت
          </span>
        <span class="small gray rtl">  {{selectedTokenType.maxWithdraw}} </span>
        </div>
      </div>
      </div>
      <div class="content-container d-flex flex-col">
        <div class="d-flex justify-space-between w100 cont">
          <span class="small gray">موجودی شما:</span>
          <span class="small gray">{{ `${$toLocal(wallet.wholePriceOfCredit, $decimal['TOMAN'])} TMN` }}</span>
        </div>
        <input @input="data.amount = $toLocal(data.amount,$decimal[wallet.relatedCoin])" v-model="data.amount" class="input w100" type="text" placeholder="مبلغ برداشت">
        <input v-model="data.destinationWalletAddress" class="input w100" type="text" placeholder="کیف پول مقصد">
        <input @input="data.code = $A2N(data.code)" v-model="data.code" v-if="otpSent" class="input w100" type="text" placeholder="شناسه دو عاملی">
        <span  v-if="otpSent" class="timer align-self-end small">{{Math.floor(countdown/60)}}:{{countdown%60}} </span>
      </div>
      <button :disabled="disable1" class="btn-primary w100" @click="send" v-if="!otpSent">{{disable1 ? disable1 : 'ارسال کد'}}</button>
      <button :disabled="disable2" class="btn-primary w100" v-if="timeout" @click="countdown=10,timeout=!timeout,countDownTimer()">ارسال
        مجدد
      </button>
      <button :disabled="disable2" class="btn-primary w100" v-if="!timeout && otpSent" @click="submit">{{disable2 ? disable2 : 'تایید'}}</button>
    </div>

  </div>
</template>

<script>
export default {
  name: "Withdraw",
  props: ['wallet'],
  data() {
    return {
      drop:false,
      TokenTypes:null,
      selectedTokenType:null,
      otpSent: false,
      countdown: 90,
      timeout: false,
      timeOut:null,
      

      data:{
        amount:0,
        destinationWalletAddress:'',
        relatedCoin:this.wallet.relatedCoin,
        code:'',
        tokenType:null,
        walletType:'P2P',
      }
    }
  },
  computed: {
    disable1(){
      let err
        if(!this.selectedTokenType){
            err= 'شبکه را انتخاب کنید'
        }else if(!this.data.amount){
          err= 'مقدار را وارد کنید'
        }else if(!this.data.destinationWalletAddress){
            err= 'شماره شبا را وارد کنید'
        }else {
          err = false
        }
      return err
    },
    disable2(){
      let err
      if(!this.data.code){
        err = 'کد را وارد کنید'
      }else{
        err = false
      }
      return err
    },
  },
  methods: {
    async submit() {
            this.state.loading = true
            this.data.amount = this.$A2N(this.data.amount)
            this.data.tokenType = this.selectedTokenType.tokenType
            const [res, ] = await this.$http.post('/wallets/withdrawal-request', this.data)
            if (res) {
                this.$emit('close')
                this.$error('درخواست شما با موفقیت ثبت شد', '', 'success')
                this.closeModale();
                // ++this.state.requestChanged

            }

    },
    async send() {
            this.data.code = ''
            this.state.loading = true
            const a = {
                EMAIL: 'ایمیل',
                SMS: 'شماره'
            }
            const res = await this.$axios.post("/users/send-code")
            if (res.message === 'code sent successfully.') {
                this.$error('', 'کد به ' + a[res.baseDTO.towStepAuthenticationType] + ' شما ارسال شد', 'success')
                this.countDown = 90
                this.countDownTimer()
                this.otpSent =  true
            }
    },
    selectToken(token){
      this.selectedTokenType=token
    },
    countDownTimer() {
      if (this.countdown > 0) {
        this.timeOut = setTimeout(() => {
          this.countdown--
          this.countDownTimer()
        }, 1000)
      } else {
        this.$error('زمان شما به اتمام رسید', '')
        this.timeout = true
      }
    },

    async getData() {
                const res = await this.$axios.get('/coins/details')
                this.TokenTypes = res.content.filter( a => a.coin == this.wallet.relatedCoin )[0].tokenTypeDetails
    },
    closeModale(){
      this.$emit('close',false)
    }
  },
  mounted() {
    this.getData();
  },
  beforeDestroy() {
    clearTimeout(this.timeOut)
  }
}
</script>

<style lang="scss" scoped>
.title-text{
  gap: .5rem;
}
.timer {
  color: var(--primary);
}

.cont {
  position: relative;
}

.input {
  background: #F0F0F0;
  border-radius: 8px 8px 0px 0px;
  height: 48px;
  padding: 10px;
}

.modal {
  display: flex;
  flex-direction: column;
  //align-items: center;
  flex-grow: 1;
  max-width: 400px;
  min-height: 200px;
  background: var(--darker-white);
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 24px;
  gap: 28px;
}

.content-container {
  gap: 24px;
}
.fee-network{
  gap: 10px;
}
button {
  height: 44px;
}
@media (max-width:500px){
  .modal{
    max-width: 90%;
    gap: .5rem;
    max-height: 400px;
    overflow: auto;
  }
}
</style>