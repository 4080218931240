<template>
  <div class="w100 wallet d-flex flex-col">
    <RialDeposit v-if="RialDeposit" @close="RialDeposit=false"/>
    <RialWithdraw :credit="toman.wholePriceOfCredit" v-if="RialWithdraw" @close="RialWithdraw=false"/>
    <div class="wallet-header">
      <div class="inventory">
        <p class="title">موجودی کیف پول</p>
        <span class="inventory-price"><h2 class="bolder">{{toman ? $toLocal(toman.wholePriceOfCredit,$decimal['TOMAN']) : ''}}</h2>
          <small class="gray">(تومان)</small>
        </span>

      </div>
      <div class="buttons">
        <button class="btn-green-outline small" @click="RialDeposit = !RialDeposit">واریز ریالی</button>
        <button class="btn-red-outline small"  @click="RialWithdraw = !RialWithdraw">برداشت ریالی</button>
      </div>
    </div>
  <Wallets :wallets="wallets" :tether="tether" :toman="toman" />
  </div>
</template>
<script>
import RialDeposit from "@/components/Panel/WalletP2P/RialDeposit";
import RialWithdraw from "@/components/Panel/WalletP2P/RialWithdraw";
import Wallets from "@/components/Panel/WalletP2P/Wallets";
export default {
  name: 'WalletP2P',
  components: {Wallets, RialWithdraw, RialDeposit},
  data() {
    return {
      RialDeposit:false,
      RialWithdraw:false,
      wallet: {
        balance: 10000000,
        transactions: [],

      },
      tether:null,
      toman:null,
      wallets:null,
    };
  },
  methods: {
    async getWallets() {
                    this.state.loading=true
                    const res = await this.$axios.get('/wallets/customer-addresses?walletType=P2P')
                    let entireRes = Object.entries(res)
                    this.sortWallets(entireRes);
            },
    sortWallets(e){
                const sortCoins = ['TOMAN', 'TETHER', 'BITCOIN', 'ETHEREUM', 'BITCOIN_CASH', 'LITE_COIN', 'RIPPLE', 'CLASSIC_ETHEREUM', 'DASH', 'DOGE_COIN'];
                e.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
                this.tether = e.filter(a => a[0] == 'TETHER')[0][1]
                this.toman = e.filter(a => a[0] == 'TOMAN')[0][1]
                this.wallets = e.map(a => {
                        let relatedCoin = a[0]
                        if(a.relatedCoin) {
                            a=a[1]
                        }else{
                            a=a[1]
                            a.relatedCoin=relatedCoin
                        }
                        return a
                        })
                    this.wallets=this.wallets.filter(wallet =>  wallet.relatedCoin !== 'TOMAN')
                    this.wallets.sort((a,b) => b.credit - a.credit)
    },
  },
  mounted() {
    this.getWallets();
  },
}
</script>
<style lang="scss" scoped>
.title{
  color: var(--darker-primary);
  font-size: 1.25em;
  font-weight: 700;
}



.wallet{
gap: 40px;
}
.wallet-header{
  display: flex;
  flex-wrap: wrap;
  gap: 4em;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}
.inventory{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 50%;
      gap: 4px;
      align-items: center;

  .inventory-price{
    display: flex;
    gap: .5rem;
    align-items: center;
  }



}
.buttons{
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  button{
    min-width: 140px;
    width: 100%;
    max-width: 140px !important;
    height: 44px;
  }
}
@media only screen and(max-width:1100px){
  .wallet-header{
    .inventory{
      width: 100%;
    }
    .buttons{
      width: 100%;
    }
  }
}
@media only screen and(max-width:700px){
  .inventory{
    flex-direction: column;
  }
  .buttons{
    align-items: center;
    justify-content: center;
  }

}
</style>